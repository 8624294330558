import { render, staticRenderFns } from "./TagColor.vue?vue&type=template&id=702a84fc&scoped=true"
import script from "./TagColor.vue?vue&type=script&lang=js"
export * from "./TagColor.vue?vue&type=script&lang=js"
import style0 from "./TagColor.vue?vue&type=style&index=0&id=702a84fc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "702a84fc",
  null
  
)

export default component.exports