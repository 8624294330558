<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
        class="body-content-overlay"
        :class="{ 'show': mqShallShowLeftSidebar }"
        @click="mqShallShowLeftSidebar = false"
    />
    <div class="todo-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
              icon="MenuIcon"
              size="21"
              class="cursor-pointer"
              @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
                :value="searchQuery"
                placeholder="Pesquise por Tarefa, Classe, Projeto, Localização, Disciplina e Subdisciplina"

                @input="updateRouteQuery"
            />
          </b-input-group>
        </div>

        <!-- Dropdown -->
        <!--        <div class="dropdown">-->
        <!--          <b-dropdown-->
        <!--            variant="link"-->
        <!--            no-caret-->
        <!--            toggle-class="p-0 mr-1"-->
        <!--            right-->
        <!--          >-->
        <!--            <template #button-content>-->
        <!--              <feather-icon-->
        <!--                icon="MoreVerticalIcon"-->
        <!--                size="16"-->
        <!--                class="align-middle text-body"-->
        <!--              />-->
        <!--            </template>-->
        <!--            <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sortBy: 'create', sortDesc: false } }">-->
        <!--              Criação A-Z-->
        <!--            </b-dropdown-item>-->
        <!--            <b-dropdown-item-->
        <!--              :to="{ name: $route.name, query: { ...$route.query, sortBy: 'create-desc', sortDesc: true } }"-->
        <!--            >-->
        <!--              Criação Z-A-->
        <!--            </b-dropdown-item>-->
        <!--            <b-dropdown-item-->
        <!--              :to="{ name: $route.name, query: { ...$route.query, sortBy: 'tarefa-asc', sortDesc: false } }"-->
        <!--            >-->
        <!--              Tarefa A-Z-->
        <!--            </b-dropdown-item>-->
        <!--            <b-dropdown-item-->
        <!--              :to="{ name: $route.name, query: { ...$route.query, sortBy: 'tarefa-desc', sortDesc: true } }"-->
        <!--            >-->
        <!--              Tarefa Z-A-->
        <!--            </b-dropdown-item>-->

        <!--          </b-dropdown>-->
        <!--        </div>-->
      </div>

      <!-- Todo List -->

      <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
          class="todo-task-list-wrapper list-group scroll-area"
          @ps-y-reach-end="loadMore"
      >
        <div class="todo-task-list media-list">
          <li
              v-for="task in tasks"
              :key="task.id"
              class="todo-item"
              :class="{ 'completed': task.isCompleted }"
              @click="handleTaskClick(task)"
          >

            <div class="todo-title-wrapper">

              <div class="todo-title-area">
                <div class="title-wrapper">
                  <b-form-checkbox
                      v-show="task.tag === 'Em Desenvolvimento'"
                      :checked="task.isCompleted"
                      @click.native.stop
                      @change="updateTaskIsCompleted(task)"
                  />
                  <div class="todo-title d-flex">
                    <div class="d-flex flex-column identificacao__tarefa">
                      <b-media vertical-align="center">
                        <template #aside>
                          <b-avatar
                              size="3.5em"
                              :src="task.projeto.cliente.imagem"
                              :text="avatarText(task.projeto.cliente.nome)"
                              :variant="'secondary'"
                              class="avatar-with-shadow"
                          />
                        </template>
                        <div class="nome__projeto ">
                          {{ task.projeto.descricao }}
                        </div>
                        <div

                            class="nome__checklist text-uppercase font-weight-bolder"
                        >

                        </div>
                        <div class="nome__checklist text-uppercase font-weight-bolder">
                          {{ task.localizacao.descricao }}
                        </div>
                        <div class="nome__checklist text-uppercase font-weight-bolder">
                          <strong>Entrega</strong>: {{
                            formatDate(task.projeto.data_entrega, {
                              month: 'numeric',
                              day: 'numeric',
                              year: 'numeric',
                            })
                          }}
                        </div>

                      </b-media>

                    </div>
                    <div class="d-flex flex-column pl-1 descricao__tarefa">
                      <div>{{ task.tarefa.descricao }}</div>
                      <div
                          v-for="sub in task.tarefa.sub_disciplinas"
                          :key="sub.uuid"
                          class="d-block font-size-7 font-weight-bold"
                      >
                        {{ sub.nome }}
                      </div>
                      <div class="d-flex mt-1">
                        <tag-color
                            v-for="(item, index) in task.disciplinas"
                            :key="index"
                            v-b-tooltip.hover.top.v-primary
                            class="cursor"
                            :texto="item.abreviacao"
                            :title="item.nome"
                            :bg-color="item.bg_color"
                        />
                      </div>
                    </div>

                  </div>

                </div>
              </div>
              <div class="todo-item-action">
                <div class="badge-wrapper mr-1">
                  <b-badge
                      pill
                      :variant="`light-${resolveTagVariant(task.tag)}`"
                      class="text-capitalize"
                  >
                    {{ resolveTagText(task.tag) }}
                  </b-badge>
                </div>
                <small class="text-nowrap text-muted mr-1">{{
                    formatDate(task.dueDate, {
                      month: 'short',
                      day: 'numeric'
                    })
                  }}</small>

                <b-avatar-group
                    v-if="task.tag === 'Em Desenvolvimento'"
                    size="30px"
                >
                  <popover-integrantes
                      :prefix="task.uuid"
                      :itens="task.projeto.times"
                      :popover-infos="{titulo:'Integrantes do time'}"
                      :badget-group="{text: 'T', variant: 'primary'}"
                  />

                </b-avatar-group>

                <b-avatar-group
                    v-if="task.tag === 'Em Aprovação'"
                    size="30px"
                >
                  <popover-integrantes
                      :prefix="task.tarefa.uuid"
                      :itens="task.projeto.users"
                      :popover-disabled="true"
                      :badget-group="{text: 'R', variant: 'secondary'}"
                  />
                </b-avatar-group>
                <b-avatar-group
                    v-if="task.tag === 'Reprovada'"
                    size="30px"
                />
                <b-avatar
                    v-if="task.tag === 'Reprovada' || task.tag === 'Concluída'"
                    v-b-tooltip.hover.top="`Executado por: ${task.executado_por.nome}`"
                    class="pull-up"
                    :src="task.executado_por.foto"
                    :variant="`light-primary`"
                    :text="avatarText(task.executado_por.nome)"
                />
                <b-avatar
                    v-if="task.tag === 'Reprovada'"
                    v-b-tooltip.hover.top="`Reprovado por: ${task.reprovado_por.nome}`"
                    class="pull-up"
                    :src="task.reprovado_por.foto"
                    :variant="`light-primary`"
                    :text="avatarText(task.reprovado_por.nome)"
                    badge=""
                    badge-variant="danger"
                />
                <b-avatar
                    v-if="task.tag === 'Concluída'"
                    v-b-tooltip.hover.top="`Aprovada por: ${task.aprovado_por.nome}`"
                    class="pull-up"
                    :src="task.aprovado_por.foto"
                    :variant="`light-primary`"
                    :text="avatarText(task.aprovado_por.nome)"
                    badge=""
                    badge-variant="success"
                />
              </div>
            </div>

          </li>
          <div
              class="no-results"
              :class="{ 'show': tasks === null }"
          >
            <b-spinner variant="primary"/>
          </div>
          <div
              class="no-results"
              :class="{ 'show': tasks ? !tasks.length : false }"
          >
            <h5>Nenhuma tarefa encontrada.</h5>
          </div>
        </div>
      </vue-perfect-scrollbar>

    </div>

    <!-- Task Handler -->

    <todo-task-handler-sidebar
        v-if="task.tag !== 'Em Desenvolvimento'"
        v-model="isTaskHandlerSidebarActive"
        :route-atual="routeAtual"
        :task="task"
        :clear-task-data="clearTaskData"
        @reprovar-task="updateTaskReprovar"
        @aprovar-task="updateTaskAprovar"
        @add-task="addTask"
        @update-task="updateTask"
        @refazer-task="updateTask"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <todo-left-sidebar
          :route-atual="routeAtual"
          :task-tags="taskTags"
          :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
          :class="{ 'show': mqShallShowLeftSidebar }"
          @close-left-sidebar="mqShallShowLeftSidebar = false"
          @aplicar-filtros="aplicarFiltros"
      />
    </portal>
  </div>
</template>

<script>
import { avatarText, formatDate } from '@core/utils/filter'
import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BFormCheckbox,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BMedia,
  BOverlay,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import { computed, onUnmounted, ref, watch, } from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { useRouter } from '@core/utils/utils'
import { useToast } from 'vue-toastification/composition'
import PopoverIntegrantes from '@/views/components/componentesPensou/PopoverIntegrantes.vue'
import store from '@/store'
import TagColor from '@/views/components/componentesPensou/TagColor.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import TodoLeftSidebar from './TodoLeftSidebar.vue'
import todoStoreModule from './todoStoreModule'
import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue'

export default {
  components: {
    TagColor,
    PopoverIntegrantes,
    BMedia,
    BAvatarGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    BBadge,
    BAvatar,
    VuePerfectScrollbar,
    BSpinner,
    BOverlay,
    // App SFC
    TodoLeftSidebar,
    TodoTaskHandlerSidebar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const ATIVIDADES_STORE_MODULE_NAME = 'atividades'
    const toast = useToast()
    const isFirstLoad = ref(true)
    const contadorLoadMore = ref(0)

    // Register module
    if (!store.hasModule(ATIVIDADES_STORE_MODULE_NAME)) {
      store.registerModule(ATIVIDADES_STORE_MODULE_NAME, todoStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ATIVIDADES_STORE_MODULE_NAME)) store.unregisterModule(ATIVIDADES_STORE_MODULE_NAME)
    })

    const {
      route,
      router,
    } = useRouter()
    const routeSortBy = computed(() => route.value.query.sort)
    const routeQuery = computed(() => route.value.query.q)
    const routeParams = computed(() => route.value.params)
    const routeAtual = computed(() => (route ? route.value.params.filter : null))
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      fetchTasks()
    })

    const tasks = ref([])

    const sortOptions = [
      'latest',
      'title-asc',
      'title-desc',
      'assignee',
      'due-date',
    ]
    const sortBy = ref(routeSortBy.value)
    watch(routeSortBy, val => {
      if (sortOptions.includes(val)) {
        sortBy.value = val
      } else {
        sortBy.value = val
      }
    })
    const resetSortAndNavigate = () => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      delete currentRouteQuery.sort

      router.replace({
        name: route.name,
        query: currentRouteQuery,
      })
          .catch(() => {
          })
    }

    const blankTask = {
      localizacao: {
        descricao: null,
      },
      projeto: {
        descricao: null,
        data_entrega: null,
        ano: null,
        cliente: [],
        times: [],
        users: [],

      },
      checklist: {
        descricao: null,

      },
      tarefa: {
        descricao: null,
      },
      executado_por: {
        nome: null,
        foto: null,
        data_hora: null,
      },
      aprovado_por: {
        nome: null,
        foto: null,
        data_hora: null,
      },
      historicos: [],
      tag: null,
    }

    const task = ref(JSON.parse(JSON.stringify(blankTask)))
    const clearTaskData = () => {
      task.value = JSON.parse(JSON.stringify(blankTask))
    }

    const addTask = val => {
      store.dispatch('atividades/addTask', val)
          .then(() => {
            // eslint-disable-next-line no-use-before-define
            fetchTasks()
          })
    }
    const removeTask = () => {
      store.dispatch('atividades/removeTask', { id: task.value.id })
          .then(() => {
            // eslint-disable-next-line no-use-before-define
            fetchTasks()
          })
    }
    const updateTask = taskData => {
      const { uuid } = taskData
      const { comentario } = taskData
      store.dispatch('atividades/updateTask', {
        action: 'executado',
        atividadeId: uuid,
        comentario,
      })
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Tarefa executada com sucesso',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            // eslint-disable-next-line no-use-before-define
            fetchTasks()
          })
    }

    const updateTaskAprovar = taskData => {
      const { uuid } = taskData
      const { comentario } = taskData

      store.dispatch('atividades/updateTask', {
        action: 'aprovado',
        atividadeId: uuid,
        comentario,
      })
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Tarefa aprovada.',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            // eslint-disable-next-line no-use-before-define
            fetchTasks()
          })
    }
    const updateTaskReprovar = taskData => {
      const { uuid } = taskData
      const { comentario } = taskData

      store.dispatch('atividades/updateTask', {
        action: 'reprovado',
        atividadeId: uuid,
        comentario,
      })
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Tarefa reprovada.',
                icon: 'XCircleIcon',
                variant: 'warning',
              },
            })

            fetchTasks()
          })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 30,
    }

    const isTaskHandlerSidebarActive = ref(false)

    const taskTags = ref([
      {
        title: 'Em desenvolvimento',
        color: 'primary',
        route: {
          name: 'apps-todo-tag',
          params: { tag: 'Em_Desenvolvimento' },
        },
      },
      {
        title: 'Em aprovação',
        color: 'warning',
        route: {
          name: 'apps-todo-tag',
          params: { tag: 'Em_Aprovação' },
        },
      },
      {
        title: 'Reprovada',
        color: 'danger',
        route: {
          name: 'apps-todo-tag',
          params: { tag: 'Reprovada' },
        },
      },
      {
        title: 'Concluída',
        color: 'success',
        route: {
          name: 'apps-todo-tag',
          params: { tag: 'Concluída' },
        },
      },
    ])

    const resolveTagVariant = tag => {
      if (tag === 'Em Desenvolvimento') return 'primary'
      if (tag === 'Concluída') return 'success'
      if (tag === 'Em Aprovação') return 'warning'
      if (tag === 'Reprovada') return 'danger'
      return 'primary'
    }

    const resolveTagText = tags => {
      if (tags.includes('Desenvolvimento')) return 'E.DEV'
      if (tags.includes('Aprovação')) return 'E.APR'
      if (tags.includes('Concluída')) return 'CONC'
      if (tags.includes('Reprovada')) return 'REPRO'
      return ''
    }

    const delayTime = ref(null)

    // Search Query
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })
    // eslint-disable-next-line no-use-before-define
    watch([searchQuery, sortBy], () => fetchTasks())
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) {
        currentRouteQuery.q = val
      } else {
        delete currentRouteQuery.q
      }

      router.replace({
        name: route.name,
        query: currentRouteQuery,
      })
    }

    const fetchTasks = () => {

      if (delayTime.value) {
        clearTimeout(delayTime.value)
      }
      delayTime.value = setTimeout(() => {
        // eslint-disable-next-line no-use-before-define
        tasks.value = null
        isTaskHandlerSidebarActive.value = false
        store.dispatch('atividades/fetchTasks', {
          q: searchQuery.value,
          filter: router.currentRoute.params.filter,
          tag: router.currentRoute.params.tag,
          sortBy: sortBy.value,
          times: route.value.query.times,
          projetos: route.value.query.projetos,
          clientes: route.value.query.clientes,
          users: route.value.query.users,
          periodo: route.value.query.periodo,
          disciplinas: route.value.query.disciplinas,
          localizacoes: route.value.query.localizacoes,

        })
            .then(response => {
              tasks.value = response.data.data
            })
      }, 300)
    }

    const fetchTasksComFiltro = () => {
      //store.state.atividades.isFirstLoad = true
      contadorLoadMore.value = 0

      if (delayTime.value) {
        clearTimeout(delayTime.value)
      }
      delayTime.value = setTimeout(() => {
        // eslint-disable-next-line no-use-before-define
        tasks.value = null
        isTaskHandlerSidebarActive.value = false
        store.dispatch('atividades/fetchTasks', {
          q: searchQuery.value,
          filter: router.currentRoute.params.filter,
          tag: router.currentRoute.params.tag,
          sortBy: sortBy.value,
          times: route.value.query.times,
          projetos: route.value.query.projetos,
          clientes: route.value.query.clientes,
          responsaveis: route.value.query.users,
          periodo: route.value.query.periodo,
          disciplinas: route.value.query.disciplinas,
          localizacoes: route.value.query.localizacoes,
          page: 1

        })
            .then(response => {
              tasks.value = response.data.data

            })
      }, 300)
    }

    fetchTasks()

    const handleTaskClick = taskData => {
      task.value = taskData
      isTaskHandlerSidebarActive.value = true
    }

    // Single Task isCompleted update
    const updateTaskIsCompleted = taskData => {
      // eslint-disable-next-line no-param-reassign
      taskData.isCompleted = !taskData.isCompleted

      updateTask(taskData)
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      task,
      tasks,
      removeTask,
      addTask,
      updateTask,
      updateTaskAprovar,
      updateTaskReprovar,
      clearTaskData,
      taskTags,
      searchQuery,
      fetchTasks,
      perfectScrollbarSettings,
      updateRouteQuery,
      resetSortAndNavigate,
      routeAtual,
      fetchTasksComFiltro,
      isFirstLoad,
      contadorLoadMore,

      // UI
      resolveTagVariant,
      resolveTagText,
      isTaskHandlerSidebarActive,

      // Click Handler
      handleTaskClick,

      // Filters
      formatDate,
      avatarText,

      // Single Task isCompleted update
      updateTaskIsCompleted,

      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
    }
  },
  methods: {
    loadMore() {

      let paginacao = this.$store.state.atividades.paginacao

      if (this.contadorLoadMore === 0) {

        this.contadorLoadMore++
        paginacao.current_page = 1

        return
      }

      if (this.contadorLoadMore > 0 && paginacao.current_page <= paginacao.last_page) {
        this.contadorLoadMore++
        paginacao.current_page++

        this.$route.query.paginacaoAtiva = true
        this.$store.dispatch('atividades/fetchTasks', {
          q: this.searchQuery,
          filter: this.routeAtual,
          tag: this.$route.params.tag,
          sortBy: this.sortBy,
          times: this.$route.query.times,
          projetos: this.$route.query.projetos,
          clientes: this.$route.query.clientes,
          responsaveis: this.$route.query.users,
          periodo: this.$route.query.periodo,
          disciplinas: this.$route.query.disciplinas,
          localizacoes: this.$route.query.localizacoes,
          page: paginacao.current_page

        })
            .then(response => {
              this.tasks = this.tasks.concat(response.data.data)
            })
      } else if (this.$route.query.paginacaoAtiva && paginacao.current_page === paginacao.last_page) {
        this.$toast.success('Não há mais tarefas a serem carregadas.')
      }
    },
    aplicarFiltros() {
      this.contadorLoadMore = 0
      this.fetchTasksComFiltro()
    },
  },

}
</script>

<style lang="scss" scoped>
.media {
  padding: 0;
}

.identificacao__tarefa {
  min-width: 230px;
  max-width: 230px;
  word-wrap: break-word;

}

.nome__projeto {
  font-size: 0.9rem;
  font-weight: 500;
}

.nome__checklist {
  font-size: 0.7rem;
}

.descricao__tarefa {
  font-size: 0.85rem;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
