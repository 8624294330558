<template>
  <app-timeline>
    <app-timeline-item
      v-for="item in itens"
      :key="item.uuid"
      :title="resolveTitulo(item.tipo)"
      :subtitle="resolveComentario(item)"
      :time="formatDataHora(item.data_hora)"
      :icon="resolveIcone(item.tipo)"
      :user="item"
      :variant="resolveVariant(item.tipo)"
    />

  </app-timeline>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'TimelineTask',
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    itens: {
      type: Array,
      default: () => ([]),
      required: true,
    },
    tag: {
      type: String,
      default: '',

    },
  },
  methods: {

    resolveComentario(item) {
      if (item.tipo === 'executado') return `Por: ${item.nome} `
      if (!item.comentario) return 'Comentário não informado.'
      return item.comentario
    },
    resolveIcone(item) {
      if (item.includes('aprovado')) return 'CheckIcon'
      if (item.includes('reprovado')) return 'XCircleIcon'
      if (item.includes('executado')) return 'CheckSquareIcon'

      return ''
    },
    resolveVariant(item) {
      if (item.includes('aprovado')) return 'success'
      if (item.includes('reprovado')) return 'danger'
      if (item.includes('executado')) return 'primary'

      return ''
    },
    resolveTitulo(item) {
      if (item.includes('aprovado')) return 'Aprovado'
      if (item.includes('reprovado')) return 'Reprovado'
      if (item.includes('executado')) return 'Executado'

      return ''
    },
    formatDataHora(value) {
      const date = new Date(value)
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',

      }
      return date.toLocaleDateString('pt-BR', options)
    },
  },
}
</script>
