(function (global, factory) {
  typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports)
    : typeof define === 'function' && define.amd ? define(['exports'], factory)
      : (global = typeof globalThis !== 'undefined' ? globalThis : global || self, factory(global.pt = {}))
}(this, (exports => {
  const fp = typeof window !== 'undefined' && window.flatpickr !== undefined
    ? window.flatpickr
    : {
      l10ns: {},
    }
  const Portuguese = {
    weekdays: {
      shorthand: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
      longhand: [
        'Domingo',
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira',
        'Sábado',
      ],
    },
    months: {
      shorthand: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ],
      longhand: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    },
    rangeSeparator: ' até ',
    time_24hr: true,
  }
  fp.l10ns.pt = Portuguese
  const pt = fp.l10ns

  exports.Portuguese = Portuguese
  exports.default = pt

  Object.defineProperty(exports, '__esModule', { value: true })
})))
